import {TenantRegistryList} from './tenantRegistry/TenantRegistryList';
import {BrandRegistryList} from './brandRegistry/BrandRegistryList';
import {TenantPoolsList} from './tenantPools/TenantPoolsList';
import {BrandRegistryOutboundList} from './brandRegistryOutbound/BrandRegistryOutboundList';
import {BrandCountryMatrix} from './brandCountryMatrix/BrandCountryMatrix';
import {ComponentType} from 'react';
import {Changelog} from './Changelog';
import {CountryRegistryList} from './countryRegistry/CountryRegistryList';
import {SystemNamesList} from './systemNames/SystemNamesList';
import {ConsentClassificationList} from './consentClassification/ConsentClassificationList';
import {DocumentTypesList} from './documentTypes/DocumentTypesList';
import {LanguageRegistryList} from './languageRegistry/LanguageRegistryList';

export interface RouteData {
  name: string;
  label: string;
  list: ComponentType;
  hideFromMenu?: boolean;
}

export enum RouteId {
  TenantRegistry = 'TenantRegistry',
  BrandRegistry = 'BrandRegistry',
  TenantPools = 'TenantPools',
  BrandRegistryOutbound = 'BrandRegistryOutbound',
  BrandCountryMatrix = 'BrandCountryMatrix',
  Changelog = 'Changelog',
  CountryRegistry = 'CountryRegistry',
  SystemNames = 'SystemNames',
  ConsentClassification = 'ConsentClassification',
  SimpleTenants = 'SimpleTenants',
  DocumentTypes = 'DocumentTypes',
  LanguageRegistry = 'LanguageRegistry',
}

export const routes: Record<RouteId, RouteData> = {
  BrandRegistry: {
    name: 'brandRegistry',
    label: 'Brand Registry',
    list: BrandRegistryList,
  },
  CountryRegistry: {
    name: 'countryRegistry',
    label: 'Country Registry',
    list: CountryRegistryList,
  },
  SystemNames: {
    name: 'systemNames',
    label: 'System Names',
    list: SystemNamesList,
  },
  ConsentClassification: {
    name: 'consentClassification',
    label: 'Consent Classification',
    list: ConsentClassificationList,
  },
  SimpleTenants: {
    name: 'simpleTenants',
    label: 'Simple Tenants',
    list: TenantRegistryList,
  },
  TenantPools: {
    name: 'tenantPools',
    label: 'Tenant Pools',
    list: TenantPoolsList,
  },
  TenantRegistry: {
    name: 'tenantRegistry',
    label: 'Full Tenant Registry',
    list: TenantRegistryList,
  },
  BrandCountryMatrix: {
    name: 'brandCountryMatrix',
    label: 'Brand-Country Matrix',
    list: BrandCountryMatrix,
  },
  BrandRegistryOutbound: {
    name: 'brandRegistryOutbound',
    label: 'Brand Registry Outbound',
    list: BrandRegistryOutboundList,
  },
  DocumentTypes: {
    name: 'documentTypes',
    label: 'Document types',
    list: DocumentTypesList,
  },
  Changelog: {
    name: 'changelog',
    label: 'Changelog',
    list: Changelog,
    hideFromMenu: true,
  },
  LanguageRegistry: {
    name: 'languageRegistry',
    label: 'Language Registry',
    list: LanguageRegistryList,
  },
};
