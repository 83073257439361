import {AuthProvider, UserIdentity} from 'react-admin';
import getIdToken from './getIdToken';
import AzureAuthenticationContext from './azure/AzureAuthenticationContext';
import {msalInstance} from './index';

const parseJson = (input: string) => {
  try {
    return JSON.parse(input);
  } catch (e) {
    return null;
  }
};

const authProvider: AuthProvider = {
  login: async (params: any): Promise<void> => {
    console.log('---------- LOGIN -----------');
    if (!params || !params.localAccountId) {
      throw new Error('Something went wrong');
    }
    localStorage.setItem('auth', JSON.stringify(params));
  },
  checkError: (_error: any): Promise<void> => {
    console.log('******* CHECK ERROR *******');
    return Promise.resolve();
  },
  checkAuth: async (_params: any): Promise<void> => {
    console.log('+++++++ CHECK AUTH ++++++++');
    try {
      const idToken = await getIdToken();
      console.log('>>>>>>>>>>>');
      console.log(idToken);
      console.log('<<<<<<<<<<<');
    } catch (e) {
      localStorage.removeItem('auth');
      localStorage.removeItem('permissions');
      localStorage.removeItem('roles');
      throw e;
    }
  },
  logout: async (): Promise<void> => {
    console.log('========= LOGOUT ==========');
    const authContext = new AzureAuthenticationContext(msalInstance);
    authContext.logout();
    localStorage.removeItem('auth');
    localStorage.removeItem('permissions');
    localStorage.removeItem('roles');
  },
  getIdentity: async (): Promise<UserIdentity> => {
    const res: UserIdentity = {
      id: '',
    };
    const accountInfo = parseJson(localStorage.getItem('auth') as string);
    if (accountInfo) {
      const roles = parseJson(localStorage.getItem('roles') as string);
      res.id = accountInfo.localAccountId;
      res.fullName = accountInfo.name
        .replace('External', `${roles.join(', ')}`)
        .replace('external', `${roles.join(', ')}`);
    }
    return res;
  },
  // authorization
  getPermissions: (): Promise<string[]> => {
    const permissions = parseJson(localStorage.getItem('permissions') as string);
    return Promise.resolve(permissions);
  },
};

export default authProvider;
