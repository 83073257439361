import {IGetListParams} from '../IGetListParams';
import {RegistryBrandOutbound, TenantBrandRegistry} from '@adecco/tenant-brand-registry';
import {uniqueId} from 'lodash';
import {sort} from '../utils/sorting';
import {matchesFilter} from '../utils/filter';

export class BrandRegistryOutboundDataProvider {
  private readonly tenantBrandRegistry = new TenantBrandRegistry();

  public getBrandRegistryOutbound(params: IGetListParams | undefined): IBrandOutbound[] {
    const q = params?.filter?.q || '';
    const brandRegistryOutbound = this.getNotFakeBrandRegistryOutbound();
    const groupedBrands = this.groupBrandsOutbound(brandRegistryOutbound);
    if (q) {
      const filteredBrands = this.filterBrands(groupedBrands, q);
      return sort(params, filteredBrands);
    } else {
      return sort(params, groupedBrands);
    }
  }

  public async getBrandRegistryOutboundJson(): Promise<Readonly<RegistryBrandOutbound[]>> {
    return this.getNotFakeBrandRegistryOutbound();
  }

  private groupBrandsOutbound(input: Readonly<RegistryBrandOutbound[]>): IBrandOutbound[] {
    const result: IBrandOutbound[] = [];
    input.forEach(({system, brands}) => {
      brands.forEach((brand) => {
        const existing = result.find((b) => b.canonicalName === brand.brand.canonicalName);
        if (existing) {
          existing[system] = brand.outboundBrandName;
        } else {
          result.push({
            id: uniqueId(),
            code: brand.brand.brandCode,
            canonicalName: brand.brand.canonicalName,
            [system]: brand.outboundBrandName,
          });
        }
      });
    });
    return result;
  }

  private filterBrands(input: IBrandOutbound[], q: string) {
    return input.filter((brand) => {
      const keys = Object.keys(brand).filter((key) => key !== 'id');
      return keys.some((key) => {
        const value = brand[key];
        const matchesValue = value && matchesFilter(value, q);
        if (['code', 'canonicalName'].indexOf(key) === -1) {
          return matchesFilter(key, q) || matchesValue;
        } else {
          return matchesValue;
        }
      });
    });
  }

  private getNotFakeBrandRegistryOutbound(): Readonly<RegistryBrandOutbound[]> {
    return this.tenantBrandRegistry.getBrandRegistryOutbound().map((outbound) => {
      const brands = outbound.brands.filter((brand) => !brand.brand.isFakeBrand);
      return {system: outbound.system, brands};
    });
  }
}

export interface IBrandOutbound {
  id: string;
  code: string | undefined;
  canonicalName: string;

  [key: string]: string | undefined;
}
