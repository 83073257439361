import {AbstractDataProvider} from '../AbstractDataProvider';
import {
  RegistryTenant,
  RegistryTenantInitialFormat,
  RootTenantId,
  TenantBrandRegistry,
} from '@adecco/tenant-brand-registry';
import {IGetListParams} from '../IGetListParams';
import {sort} from '../utils/sorting';

export class TenantRegistryDataProvider extends AbstractDataProvider {
  protected readonly resource = '';
  protected readonly tenantBrandRegistry = new TenantBrandRegistry();

  public async getOne(id: string): Promise<any> {
    return {};
  }

  protected generateId(item: any) {
    return item.id;
  }

  protected parseId(id: string): Record<string, any> {
    const [principalName, regionId] = id.split('-');
    return {principalName, regionId};
  }

  public async getAllTenantRegistry(params: IGetListParams | undefined): Promise<Readonly<RegistryTenant[]>> {
    const tenants = this.tenantBrandRegistry.getAllTenants({includeRootTenantId: true}).map(tenant => {
      const brandName = tenant.brand?.canonicalName || ''
      const fake = (tenant.brand?.isFakeBrand || false) ? '(FAKE)' : ''
        return { ...tenant, displayName: `${brandName} ${fake}` }
    });
    return sort(params, [...tenants]);
  }

  public async getTenantRegistryJson(): Promise<Readonly<RegistryTenantInitialFormat[]>> {
    return this.tenantBrandRegistry.getAllTenantsInInitialFormat().map((tenant) => {
      if (tenant.id in RootTenantId) {
        const pool = this.tenantBrandRegistry.getTenantPools().find((pool) => pool.rootTenant === tenant.id);
        const collapsedTenants = pool?.collapsedTenants || [];
        return {
          ...tenant,
          collapsedTenants,
        };
      } else {
        return tenant;
      }
    });
  }
}
