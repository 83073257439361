import {Datagrid, List, TextField} from 'react-admin';
import {DataGridSpinner} from '../DataGridSpinner';
import {useDataGridClassNames} from '../styles/datagridStyles';
import {JsonSwitchTable} from '../JsonSwitchTable';
import {Box} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

const BrandRegistryTable = (props: any) => {
  const datagridClass = useDataGridClassNames();
  return (
    <>
      <Box mt={2}>
        <Alert severity="info">The brand codes starting with "CB" are created by the CBE team.</Alert>
      </Box>
      <List {...props} bulkActionButtons={false} exporter={false} empty={false} pagination={false}>
        <DataGridSpinner key="1">
          <Datagrid isRowSelectable={() => false} classes={datagridClass}>
            <TextField source="brandCode" label="Brand Code" sortBy="brandCode" />
            <TextField source="canonicalName" label="Canonical Brand Name" sortBy="canonicalName" />
            <TextField source="aliases" label="Aliases" sortBy="aliases" />
          </Datagrid>
        </DataGridSpinner>
      </List>
    </>
  );
};

export const BrandRegistryList = (props: any) => {
  return <JsonSwitchTable {...props} table={BrandRegistryTable} />;
};
