import {JsonSwitchTable} from '../JsonSwitchTable';
import {useDataGridClassNames} from '../styles/datagridStyles';
import {Datagrid, List, TextField} from 'react-admin';
import {DataGridSpinner} from '../DataGridSpinner';

const LanguageRegistryTable = (props: any) => {
  const datagridClass = useDataGridClassNames();
  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
      empty={false}
      pagination={false}
      sort={{field: 'name', order: 'ASC'}}
    >
      <DataGridSpinner key="1">
        <Datagrid isRowSelectable={() => false} classes={datagridClass}>
          <TextField source="id" label="ID" sortBy="id" />
          <TextField source="name" label="Name" sortBy="name" />
          <TextField source="iso2" label="iso2" sortBy="iso2" />
        </Datagrid>
      </DataGridSpinner>
    </List>
  );
};

export const LanguageRegistryList = (props: any) => {
  return <JsonSwitchTable {...props} table={LanguageRegistryTable} />;
};
