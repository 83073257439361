import {IGetListParams} from '../IGetListParams';
import {get} from 'lodash';

export const sort = <T>(params: IGetListParams | undefined, source: T[]): T[] => {
  if (params?.sort && params.sort?.field) {
    const key = params.sort?.field;
    if (params.sort.order === 'ASC')
      return [...source].sort((a: any, b: any) => {
        const aValue = get(a, key);
        const bValue = get(b, key);
        if (!aValue && !bValue) {
          return 0;
        }
        if (aValue && !bValue) {
          return 1;
        }
        if (!aValue && bValue) {
          return -1;
        }
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      });
    else {
      return [...source].sort((a: any, b: any) => {
        const aValue = get(a, key);
        const bValue = get(b, key);
        if (!aValue && !bValue) {
          return 0;
        }
        if (aValue && !bValue) {
          return -1;
        }
        if (!aValue && bValue) {
          return 1;
        }
        if (aValue === bValue) {
          return 0;
        }
        return bValue > aValue ? 1 : -1;
      });
    }
  }
  return source;
};
