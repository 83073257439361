import {Button, ListToolbar, Loading, Title, TopToolbar, useQueryWithStore} from 'react-admin';
import {Card, CardContent, Snackbar} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {useState} from 'react';
import {Alert} from '@material-ui/lab';

export interface ListJsonProps {
  resource: string;
  label: string;
  switchAction: JSX.Element;
}

export const ListJson = (props: ListJsonProps) => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const resource = props.resource + 'Json';
  const title = props.label + ' JSON';
  const {data, loading, error} = useQueryWithStore({
    type: 'getList',
    resource,
    payload: {},
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>Error: {error}</p>;
  }

  const jsonContent = JSON.stringify(data[0][resource], null, 2);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(jsonContent);
    setSnackbarOpen(true);
  };
  const copyJsonAction = (
    <Button onClick={copyToClipboard} label="Copy to clipboard">
      <FileCopyIcon />
    </Button>
  );
  const actions = (
    <TopToolbar>
      {copyJsonAction}
      {props.switchAction}
    </TopToolbar>
  );

  const snackbar = (
    <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={() => setSnackbarOpen(false)}>
      <Alert severity="success" onClose={() => setSnackbarOpen(false)}>
        Copied to clipboard
      </Alert>
    </Snackbar>
  );

  return (
    <>
      <Card raised={false} style={{backgroundColor: '#fafafa', boxShadow: 'none'}}>
        <Title title={title} />
        <CardContent>
          <>
            <ListToolbar actions={actions} />
            <Card>
              <pre style={{margin: 0, padding: 12}}>{jsonContent}</pre>
            </Card>
          </>
        </CardContent>
      </Card>
      {snackbar}
    </>
  );
};
