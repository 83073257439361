import {LanguageRegistry} from '@adecco/tenant-brand-registry';
import {IGetListParams} from '../IGetListParams';
import {sort} from '../utils/sorting';


export class LanguageRegistryDataProvider {

  public getAll(params: IGetListParams | undefined): LanguageRow[] {
    const rows = this.getAllJson();
    return sort(params, rows);
  }

  public getAllJson(): LanguageRow[] {
    return Object.entries(LanguageRegistry).map(([id, language]) => ({
      id,
      name: language.name,
      iso2: language.iso2,
    }));
  }
}

export interface LanguageRow {
  name: string;
  iso2: string;
}
