import {BrandRegistryDataProvider} from './brandRegistry/BrandRegistryDataProvider';
import {IGetListParams} from './IGetListParams';
import {TenantPoolsDataProvider} from './tenantPools/TenantPoolsDataProvider';
import {TenantRegistryDataProvider} from './tenantRegistry/TenantRegistryDataProvider';
import {BrandRegistryOutboundDataProvider} from './brandRegistryOutbound/BrandRegistryOutboundDataProvider';
import {BrandCountryMatrixDataProvider} from './brandCountryMatrix/BrandCountryMatrixDataProvider';
import {CountryRegistryDataProvider} from './countryRegistry/CountryRegistryDataProvider';
import {SystemNamesDataProvider} from './systemNames/SystemNamesDataProvider';
import {ConsentClassificationDataProvider} from './consentClassification/ConsentClassificationDataProvider';
import {SimpleTenantsDataProvider} from './simpleTenants/SimpleTenantsDataProvider';
import {DocumentTypesDataProvider} from './documentTypes/DocumentTypesDataProvider';
import {LanguageRegistryDataProvider} from './languageRegistry/LanguageRegistryDataProvider';

const prepareDataForResource = <T extends any>(resource: string, data: Readonly<T[]>) => {
  if (resource.endsWith('Json')) {
    return {data: [{id: 1, [resource]: data}], total: 1};
  } else {
    return {data, total: data.length};
  }
};

const DataProvider = () => ({
  getList: async (resource: string, params: IGetListParams): Promise<any> => {
    switch (resource) {
      case 'tenantRegistry':
        const tenantRegistryDataProvider = new TenantRegistryDataProvider('', null);
        const tenantRegistryData = await tenantRegistryDataProvider.getAllTenantRegistry(params);
        return prepareDataForResource(resource, tenantRegistryData);
      case 'tenantRegistryJson':
        const tenantRegistryJsonDataProvider = new TenantRegistryDataProvider('', null);
        const tenantRegistryJson = await tenantRegistryJsonDataProvider.getTenantRegistryJson();
        return prepareDataForResource(resource, tenantRegistryJson);
      case 'brandRegistryOutbound':
        const brandRegistryOutboundDataProvider = new BrandRegistryOutboundDataProvider();
        const brandRegistryOutboundData = await brandRegistryOutboundDataProvider.getBrandRegistryOutbound(params);
        return prepareDataForResource(resource, brandRegistryOutboundData);
      case 'brandRegistryOutboundJson':
        const brandRegistryOutboundJsonDataProvider = new BrandRegistryOutboundDataProvider();
        const brandRegistryOutboundJson = await brandRegistryOutboundJsonDataProvider.getBrandRegistryOutboundJson();
        return prepareDataForResource(resource, brandRegistryOutboundJson);
      case 'brandRegistry':
        const brandRegistryDataProvider = new BrandRegistryDataProvider('', null);
        const brandRegistryData = await brandRegistryDataProvider.getAllBrandRegistry(params);
        return prepareDataForResource(resource, brandRegistryData);
      case 'brandRegistryJson':
        const brandRegistryJsonDataProvider = new BrandRegistryDataProvider('', null);
        const brandRegistryJson = await brandRegistryJsonDataProvider.getAllBrandRegistryJson();
        return prepareDataForResource(resource, brandRegistryJson);
      case 'tenantPools':
        const tenantPoolsDataProvider = new TenantPoolsDataProvider('', null);
        const tenantPoolsData = await tenantPoolsDataProvider.getAllPools(params);
        return prepareDataForResource(resource, tenantPoolsData);
      case 'tenantPoolsJson':
        const tenantPoolsJsonDataProvider = new TenantPoolsDataProvider('', null);
        const tenantPoolsJson = await tenantPoolsJsonDataProvider.getTenantPoolsJson();
        return prepareDataForResource(resource, tenantPoolsJson);
      case 'brandCountryMatrix':
        const brandCountryMatrixDataProvider = new BrandCountryMatrixDataProvider();
        const brandCountryMatrixData = await brandCountryMatrixDataProvider.getBrandCountryMatrixData(params);
        return prepareDataForResource(resource, brandCountryMatrixData);
      case 'countryRegistry':
        const countryRegistryDataProvider = new CountryRegistryDataProvider();
        const countryRegistryData = countryRegistryDataProvider.getAllCountries(params);
        return prepareDataForResource(resource, countryRegistryData);
      case 'countryRegistryJson':
        const countryRegistryJsonDataProvider = new CountryRegistryDataProvider();
        const countryRegistryJsonData = countryRegistryJsonDataProvider.getAllCountriesJson();
        return prepareDataForResource(resource, countryRegistryJsonData);
      case 'systemNames':
        const systemNamesDataProvider = new SystemNamesDataProvider();
        const systemNamesData = systemNamesDataProvider.getAllSystemNames(params);
        return prepareDataForResource(resource, systemNamesData);
      case 'systemNamesJson':
        const systemNamesJsonDataProvider = new SystemNamesDataProvider();
        const systemNamesJsonData = systemNamesJsonDataProvider.getAllSystemNamesJson();
        return prepareDataForResource(resource, systemNamesJsonData);
      case 'consentClassification':
        const consentClassificationDataProvider = new ConsentClassificationDataProvider();
        const consentClassificationData = consentClassificationDataProvider.getAllConsentClassifications(params);
        return prepareDataForResource(resource, consentClassificationData);
      case 'consentClassificationJson':
        const consentClassificationJsonDataProvider = new ConsentClassificationDataProvider();
        const consentClassificationJsonData = consentClassificationJsonDataProvider.getAllConsentClassificationsJson();
        return prepareDataForResource(resource, consentClassificationJsonData);
      case 'simpleTenants':
        const simpleTenantsDataProvider = new SimpleTenantsDataProvider();
        const simpleTenantsData = simpleTenantsDataProvider.getAllSimpleTenants(params);
        return prepareDataForResource(resource, simpleTenantsData);
      case 'simpleTenantsJson':
        const simpleTenantsJsonDataProvider = new SimpleTenantsDataProvider();
        const simpleTenantsJsonData = simpleTenantsJsonDataProvider.getAllSimpleTenantsJson();
        return prepareDataForResource(resource, simpleTenantsJsonData);
      case 'documentTypes':
        return prepareDataForResource(resource, new DocumentTypesDataProvider().getAll(params));
      case 'documentTypesJson':
        return prepareDataForResource(resource, new DocumentTypesDataProvider().getAllJson());
      case 'languageRegistry':
        return prepareDataForResource(resource, new LanguageRegistryDataProvider().getAll(params));
      case 'languageRegistryJson':
        return prepareDataForResource(resource, new LanguageRegistryDataProvider().getAllJson());

      default:
        return {data: [], total: 0};
    }
  },
  getOne: async (resource: string, params: any): Promise<any> => {
    return {data: null};
  },
  getMany: async (): Promise<any> => ({
    data: [],
  }),
  getManyReference: async (): Promise<any> => ({
    data: [],
    total: 0,
  }),
  update: async (): Promise<any> => ({
    data: null,
  }),
  updateMany: async (): Promise<any> => ({
    data: [],
  }),
  create: async (): Promise<any> => ({
    data: null,
  }),
  delete: async (): Promise<any> => ({
    data: null,
  }),
  deleteMany: async (): Promise<any> => ({
    data: [],
  }),
});

export default DataProvider;
