import {Card, CardContent} from '@material-ui/core';
import {Title} from 'react-admin';

const NotFound = (props: any): any => {
  return (
    <Card>
      <Title title="Not Found" />
      <CardContent>
        <h1>404: Page not found</h1>
      </CardContent>
    </Card>
  );
};

export default NotFound;
