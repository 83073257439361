import {Datagrid, List, TextField} from 'react-admin';
import {DataGridSpinner} from '../DataGridSpinner';
import {useDataGridClassNames} from '../styles/datagridStyles';
import {JsonSwitchTable} from '../JsonSwitchTable';

const TenantRegistryTable = (props: any) => {
  const datagridClass = useDataGridClassNames();
  return (
    <List {...props} bulkActionButtons={false} exporter={false} empty={false} pagination={false}>
      <DataGridSpinner key="1">
        <Datagrid isRowSelectable={() => false} classes={datagridClass}>
          <TextField source="id" label="Id" sortBy="id" />
          <TextField source="name" label="Name" sortBy="name" />
          <TextField source="displayName" label="Brand" sortBy="brand.canonicalName" />
          <TextField source="country.name" label="Country" sortBy="country.name" />
          <TextField source="country.iso2" label="Iso2" sortBy="country.iso2" />
          <TextField source="alias" label="Alias" sortBy="alias" />
          <TextField source="rootTenantId" label="Tenant pool" sortBy="rootTenantId" />
        </Datagrid>
      </DataGridSpinner>
    </List>
  );
};

export const TenantRegistryList = (props: any) => {
  return <JsonSwitchTable {...props} table={TenantRegistryTable} />;
};
