import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    fontWeight: 'bold',
    background: 'red',
  },
  headerCell: {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: '3px solid #949090',
    fontWeight: 'bold',
  },
  cellBorderColor: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  rowOdd: {
    background: '#fbfbfb',
  },
});

export const useDataGridClassNames = () => {
  const classes = useStyles();
  return {rowCell: classes.cellBorderColor, headerCell: classes.headerCell, rowOdd: classes.rowOdd};
};
