import './App.css';
import {Admin, Resource} from 'react-admin';
import DataProvider from './DataProvider';
import AuthProvider from './AuthProvider';
import Dashboard from './Dashboard';
import LoginPage from './LoginPage';
import {createBrowserHistory as createHistory} from 'history';
import {IPublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import theme from './theme';
import NotFound from './NotFound';
import {LayoutPsa} from './Layout';
import {routes} from './routes';

type AppProps = {
  pca: IPublicClientApplication;
};
const dataProvider = DataProvider();
const history = createHistory();
const isProductionZone = ['production', 'uat'].includes(process.env.REACT_APP_ENV ?? '');
const App = ({pca}: AppProps) => {
  const params = window.location.pathname.split('/').filter((x) => x !== '');
  const devSupport = params.includes('devSupport');
  return (
    <MsalProvider instance={pca}>
      <Admin
        theme={theme}
        dashboard={!devSupport ? Dashboard : undefined}
        loginPage={LoginPage}
        authProvider={AuthProvider}
        dataProvider={dataProvider}
        history={history}
        layout={LayoutPsa}
        catchAll={() => <NotFound devSupport={devSupport && !isProductionZone} />}
      >
        {Object.values(routes).map((route) => (
          <Resource key={route.name} name={route.name} options={{label: route.label}} list={route.list} />
        ))}
      </Admin>
    </MsalProvider>
  );
};

export default App;
