import {IPagination, ISort} from './IGetListParams';

export abstract class AbstractDataProvider {
  protected abstract readonly resource: string;

  protected readonly apiUrl: string;

  protected readonly httpClient;

  constructor(apiUrl: string, httpClient: any) {
    this.apiUrl = apiUrl;
    this.httpClient = httpClient;
  }

  public async getOne(id: string): Promise<any> {
    const url = new URL(`${this.apiUrl}/${this.resource}/${id}`);
    const res = await this.httpClient(url.toString(), {method: 'GET'});

    return this.expandWithId(res.json);
  }

  public async getList(pagination: IPagination | undefined, sort: ISort | undefined, filter: Record<string, any> | undefined): Promise<any[]> {
    const url = new URL(`${this.apiUrl}/${this.resource}`);
    if (pagination) {
      const {page, perPage} = pagination;
      const limit = perPage || 10;
      let offset = 0;
      if (page && page > 1) {
        offset = (page - 1) * limit;
        url.searchParams.set('offset', '' + offset);
      }
      url.searchParams.set('limit', '' + limit);
    }
    if (sort) {
      const {field, order} = sort;
      if (field) {
        url.searchParams.set('sort', (order === 'DESC' ? '-' : '+') + field);
      }
    }
    if(filter && Object.keys(filter).length>0){
      for(let key of Object.keys(filter)){
          url.searchParams.set(key, filter[key]);
      }
    }
    const res = await this.httpClient(url.toString(), {method: 'GET'});

    return res.json.map((item: any) => this.expandWithId(item));
  }

  public async getCount(filter: Record<string, any> | undefined): Promise<number> {
    const url = new URL(`${this.apiUrl}/${this.resource}/_count`);
    
    if(filter && Object.keys(filter).length>0){
      for(let key of Object.keys(filter)){
          url.searchParams.set(key, filter[key]);
      }
    }
    const res = await this.httpClient(url.toString(), {method: 'GET'});
    
    return res.json;
  }

  protected expandWithId(item: any): any {
    item.id = item.id || this.generateId(item);
    return item;
  }

  protected generateId(item: any): any {
    return item.id;
  }
}
