import ChangelogMarkdown from '@adecco/tenant-brand-registry/CHANGELOG.md';
import ReactMarkdown from 'react-markdown';
import {useEffect, useState} from 'react';

export const Changelog = () => {
  let [content, setContent] = useState('');

  useEffect(() => {
    fetch(ChangelogMarkdown)
      .then((res) => res.text())
      .then((md) => setContent(md));
  }, []);

  return <ReactMarkdown children={content} />;
};
