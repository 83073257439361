import {Configuration, LogLevel} from '@azure/msal-browser';

const AzureActiveDirectoryAppClientId: any = process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID;
const AzureActiveDirectoryTenantId: any = process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_TENANT_ID;

export const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: AzureActiveDirectoryAppClientId,
    ...(AzureActiveDirectoryTenantId
      ? {authority: `https://login.microsoftonline.com/${AzureActiveDirectoryTenantId}`}
      : {}),
      redirectUri: '/'
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};
