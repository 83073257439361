import {
  AuthenticationResult,
  AccountInfo,
  RedirectRequest,
  PopupRequest,
  IPublicClientApplication,
} from '@azure/msal-browser';

import {MSAL_CONFIG} from './AzureAuthenticationConfig';

export class AzureAuthenticationContext {
  private myMSALObj: IPublicClientApplication;
  private scopes: string[] = [];
  private account?: AccountInfo;
  private loginRedirectRequest?: RedirectRequest;
  private loginRequest?: PopupRequest;

  public isAuthenticationConfigured = false;

  constructor(instance: IPublicClientApplication) {
    // @ts-ignore
    this.account = null;
    this.setRequestObjects();
    if (MSAL_CONFIG?.auth?.clientId) {
      this.isAuthenticationConfigured = true;
    }
    this.myMSALObj = instance;
  }

  private setRequestObjects(): void {
    this.loginRequest = {
      scopes: this.scopes,
      prompt: 'select_account',
    };

    this.loginRedirectRequest = {
      ...this.loginRequest,
      redirectStartPage: window.location.href,
    };
  }

  login(signInType: string): void {
    this.myMSALObj
      .handleRedirectPromise()
      .then((authResult) => {})
      .catch((err) => {
        console.log(err);
      });
    this.myMSALObj.loginRedirect(this.loginRedirectRequest);
  }

  public logout(): void {
    this.myMSALObj.setActiveAccount(null);
  }

  async acquireToken(account?: AccountInfo): Promise<AuthenticationResult> {
    let data;
    try {
      data = await this.myMSALObj.acquireTokenSilent({
        scopes: this.scopes,
        account,
      });
    } catch (e) {
      data = await this.myMSALObj.acquireTokenPopup({scopes: this.scopes});
    }
    return data;
  }
}

export default AzureAuthenticationContext;
