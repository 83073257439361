import React, {useState, useEffect, useMemo, useCallback} from 'react';
import AzureAuthenticationContext from './AzureAuthenticationContext';
import {AccountInfo} from '@azure/msal-browser';
import './AzureAuthentication.css';
import {useMsal} from '@azure/msal-react';

// Log In, Log Out button
const AzureAuthenticationButton = ({onAuthenticated}: any): JSX.Element => {
  const {instance} = useMsal();
  const [, setUser] = useState<AccountInfo>();

  const returnedAccountInfo = useCallback(
    (user: AccountInfo) => {
      onAuthenticated(user);
      setUser(user);
    },
    [onAuthenticated],
  );

  // Azure client context
  const authenticationModule: AzureAuthenticationContext = useMemo(() => {
    return new AzureAuthenticationContext(instance);
  }, [instance]);

  useEffect(() => {
    setTimeout(() => {
      const activeAccount = instance.getActiveAccount();
      if (activeAccount) {
        returnedAccountInfo(activeAccount);
      } else {
        authenticationModule.login('loginRedirect');
      }
    }, 1500);
  }, [authenticationModule, instance, returnedAccountInfo]);

  return (
    <div id="authentication" className="AzureAuthentication">
      {authenticationModule.isAuthenticationConfigured ? (
        'Authenticating...'
      ) : (
        <div>Authentication Client ID is not configured.</div>
      )}
    </div>
  );
};

export default AzureAuthenticationButton;
