import './BrandCountryMatrix.css';

import {Filter, List, SearchInput, useListContext} from 'react-admin';
import {DataGridSpinner} from '../DataGridSpinner';
import {IBrandCountryMatrixRow} from './BrandCountryMatrixDataProvider';
import {omit} from 'lodash';
import {FC} from 'react';
import {Box, Typography} from '@material-ui/core';

interface EmptyResultProps {
  q?: string;
}

const EmptyResult: FC<EmptyResultProps> = ({q}) => {
  return (
    <Box textAlign="center" m={2}>
      <Typography variant="h4">No results {q ? `for ${q}` : ''}</Typography>
    </Box>
  );
};

const BrandCountryMatrixDataGrid = () => {
  const {data, filterValues} = useListContext();
  const dataList = Object.values(data || {}) as IBrandCountryMatrixRow[];

  if (dataList.length === 0) {
    return <EmptyResult q={filterValues.q} />;
  }

  const columns = dataList
    .map((value) => Object.keys(omit(value, 'id', 'name')))
    .reduce((accumulator, value) => accumulator.concat(value), [])
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort();

  const rows = dataList
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((row) => {
      return (
        <tr key={row.id}>
          <td className="left-header">{row['name']}</td>
          {columns.map((column) => (
            <td key={column}>{row[column]}</td>
          ))}
        </tr>
      );
    });

  return (
    <table>
      <thead>
        <tr>
          <th className="top-left-header">Country</th>
          {columns.map((column) => (
            <th className="top-header" key={column}>
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

export const BrandCountryMatrix = (props: any) => {
  const MatrixFilter = (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
    </Filter>
  );
  return (
    <List {...props} bulkActionButtons={false} exporter={false} empty={false} pagination={false} filters={MatrixFilter}>
      <DataGridSpinner key="1">
        <BrandCountryMatrixDataGrid {...props} />
      </DataGridSpinner>
    </List>
  );
};
