import React from 'react';
import {useLogin, useNotify, defaultTheme} from 'react-admin';
import {ThemeProvider} from '@material-ui/styles';
import {createTheme} from '@material-ui/core/styles';
import AzureAuthenticationButton from './azure/AzureAuthenticationButton';

const LoginPage = ({theme}: any) => {
  const login = useLogin();
  const notify = useNotify();

  const azureAuth = (azureUser: any) => {
    login(azureUser).catch(() => notify('Something went wrong with Azure AD authorization'));
  };

  return (
    <ThemeProvider theme={theme || createTheme(defaultTheme)}>
      <AzureAuthenticationButton onAuthenticated={azureAuth} />
    </ThemeProvider>
  );
};

export default LoginPage;
