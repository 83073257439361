import {DocumentType} from '@adecco/tenant-brand-registry';
import {IGetListParams} from '../IGetListParams';
import {sort} from '../utils/sorting';
import {SystemNameRow} from '../systemNames/SystemNamesDataProvider';

export class DocumentTypesDataProvider {
  public getAll(params: IGetListParams | undefined): SystemNameRow[] {
    const rows = this.getAllJson();
    return sort(params, rows);
  }

  public getAllJson(): SystemNameRow[] {
    return Object.values(DocumentType).map((name: string, id: number) => {
      return {id, name};
    });
  }
}
