import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {PublicClientApplication, EventType, EventMessage, AuthenticationResult} from '@azure/msal-browser';
import {MSAL_CONFIG} from './azure/AzureAuthenticationConfig';

export const msalInstance = new PublicClientApplication(MSAL_CONFIG);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  <React.StrictMode>
    <App pca={msalInstance} />
  </React.StrictMode>,
  document.getElementById('root'),
);
