import {useState} from 'react';
import {ListJson} from './ListJson';
import {Button, TopToolbar} from 'react-admin';
import StorageIcon from '@material-ui/icons/Storage';

export const JsonSwitchTable = (props: any) => {
  const [isJsonShown, setJsonShown] = useState<boolean>(false);

  if (isJsonShown) {
    const switchToTableAction = (
      <Button onClick={() => setJsonShown(false)} label="Switch to table view">
        <StorageIcon />
      </Button>
    );
    return <ListJson resource={props.resource} label={props.options.label} switchAction={switchToTableAction} />;
  }

  const TableComponent = props.table;
  const actions = (
    <TopToolbar>
      <Button onClick={() => setJsonShown(true)} label="Switch to JSON view">
        <StorageIcon />
      </Button>
    </TopToolbar>
  );
  return <TableComponent {...props} actions={actions} />;
};
