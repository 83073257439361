import * as React from 'react';
import {useRecordContext} from 'react-admin';
import {ICollapsedTenant} from './TenantPoolsDataProvider';

export const CollapsedTenantItem = (props: any) => {
  const {source} = props;
  const record = useRecordContext(props);
  const collapsedTenants = record[source] as {[key: string]: ICollapsedTenant[]};
  const countries = Object.entries(collapsedTenants)
    .sort()
    .map(([key, value]) => {
      const listItems = (Array.isArray(value) ? value : []).map(({id, brand}) => {
        return (
          <li key={id}>
            {id} {brand && <span>({brand})</span>}
          </li>
        );
      });
      return (
        <>
          <span>{key}:</span>
          <ul>{listItems}</ul>
        </>
      );
    });
  return <>{countries}</>;
};
