import {Country, TenantBrandRegistry, TenantPools} from '@adecco/tenant-brand-registry';
import {isNil, omit, uniqueId} from 'lodash';
import {sort} from '../utils/sorting';
import {IGetListParams} from '../IGetListParams';
import {matchesFilter} from '../utils/filter';

export class BrandCountryMatrixDataProvider {
  private readonly tenantBrandRegistry = new TenantBrandRegistry();

  public async getBrandCountryMatrixData(params: IGetListParams | undefined): Promise<IBrandCountryMatrixRow[]> {
    const q = params?.filter?.q || '';
    const tenants = this.tenantBrandRegistry.getAllTenants().filter((tenant) => !tenant.brand?.isFakeBrand);
    const brandRegistry = this.tenantBrandRegistry.getBrandRegistry().filter((brand) => !brand.isFakeBrand);
    const countries = new Set<Country>(
      tenants.map((tenant) => tenant.country).filter((country): country is Country => !isNil(country)),
    );
    const rows = Array.from(countries.values())
      .map((country) => {
        const countryName = country.name + ` (${country.iso2})`;
        const rowValues = brandRegistry.reduce((result, current) => {
          const brandNameMatches =
            matchesFilter(current.canonicalName, q) || (current.aliases || []).some((alias) => matchesFilter(q, alias));
          const tenant = tenants.find((tenant) => {
            return tenant.country === country && tenant.brand === current;
          });
          if (tenant) {
            const tenantIdMatches = matchesFilter(tenant.id.toString(), q);
            const tenantNameMatches = matchesFilter(tenant.name, q);
            const rootTenant = this.getRootTenant(tenant.id);
            const rootTenantIdMatches = isNil(rootTenant) ? false : matchesFilter(rootTenant.toString(), q);
            const countryMatches = [tenant.country?.name, tenant.country?.iso2, tenant.country?.iso3].some((s) =>
              matchesFilter(s || '', q),
            );
            if (brandNameMatches || tenantIdMatches || tenantNameMatches || rootTenantIdMatches || countryMatches) {
              result[current.canonicalName] = rootTenant ? `${tenant.id} (${rootTenant})` : tenant.id.toString();
            }
          } else {
            if (brandNameMatches) {
              result[current.canonicalName] = '';
            }
          }
          return result;
        }, {} as Record<string, string>);
        return {id: uniqueId(), name: countryName, ...rowValues};
      })
      .filter((row) => Object.values(omit(row, 'id', 'name')).some((value) => !!value));
    return sort(params, rows);
  }

  private getRootTenant(tenantId: number): number | null {
    const pool = TenantPools.find((pool) => pool.collapsedTenants.indexOf(tenantId) !== -1);
    return pool ? pool.rootTenant : null;
  }
}

export interface IBrandCountryMatrixRow {
  id: string;
  name: string;

  [key: string]: string;
}
