import { defaultTheme } from 'react-admin';
import _ from 'lodash';

const theme = _.merge({}, defaultTheme, {
  typography: {
    fontFamily: ['Montserrat', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
  },
  sidebar: {
    width: 300,
    closedWidth: 55,
  },
  menu: {
    width: 300,
    closedWidth: 55,
  },
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        backgroundColor: '#DA291C',
      },
    },
    RaList: {
      root: {
        paddingTop: 25,
      }
    },
    RaMenuItemLink: {
      root: {
        color: '#C1C1C1',
        fontWeight: 'bold'
      },
    },
    RaAppBar: {
      title: {
        fontWeight: 'bold',
        fontStyle: 'normal',
      }
    }
  },
});

export default theme;
