import {AbstractDataProvider} from '../AbstractDataProvider';
import {RegistryBrand, TenantBrandRegistry} from '@adecco/tenant-brand-registry';
import {IGetListParams} from '../IGetListParams';
import {uniqueId} from 'lodash';
import {sort} from '../utils/sorting';

export class BrandRegistryDataProvider extends AbstractDataProvider {
  protected readonly resource = '';

  private readonly tenantBrandRegistry = new TenantBrandRegistry();

  public async getOne(id: string): Promise<any> {
    return {};
  }

  protected generateId(item: any) {
    return item.id;
  }

  protected parseId(id: string): Record<string, any> {
    const [principalName, regionId] = id.split('-');
    return {principalName, regionId};
  }

  public async getAllBrandRegistry(params: IGetListParams | undefined): Promise<IRegistryBrand[]> {
    const brandRegistry = this.getNotFakeBrands();
    const brands: any[] = [...brandRegistry].map((x) => {
      return {id: uniqueId(), brandCode: x.brandCode, canonicalName: x.canonicalName, aliases: x.aliases?.join(', ')};
    });
    return sort(params, brands);
  }

  public async getAllBrandRegistryJson(): Promise<Readonly<RegistryBrand[]>> {
    return this.getNotFakeBrands();
  }

  private getNotFakeBrands(): Readonly<RegistryBrand[]> {
    return this.tenantBrandRegistry.getBrandRegistry().filter((brand) => !brand.isFakeBrand);
  }
}

export interface IRegistryBrand {
  id: string;
  canonicalName: string;
  brandCode?: string;
  aliases?: string;
}
