import {Box, Container, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {CSSProperties} from '@material-ui/styles';
import {Alert} from '@material-ui/lab';

const titleStyle: CSSProperties = {
  color: '#DA291C',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: 72,
  marginTop: 100,
  marginBottom: 0,
};

const subTitleStyle = {
  ...titleStyle,
  fontStyle: 'normal',
  fontSize: 32,
  marginTop: 0,
};

const useStyles = makeStyles({
  title: titleStyle,
  subtitle: subTitleStyle,
});

const Dashboard = (props: any) => {
  const classes = useStyles();
  const href = 'https://adeccogroup.atlassian.net/wiki/spaces/COMMON/pages/14490009518/The+Adecco+Tenant+Registry+LINK';
  const link = (
    <a target="_blank" rel="noreferrer" href={href}>
      here
    </a>
  );
  return (
    <Container maxWidth="lg">
      <Box textAlign="center">
        <Typography paragraph className={classes.title}>
          {props.title ? props.title : 'Adecco Tenant-Brand Registry'}
        </Typography>
      </Box>
      <Box mt={2}>
        <Alert severity="info">To read more about the Tenant-Brand Registry, go {link}</Alert>
      </Box>
    </Container>
  );
};

export default Dashboard;
