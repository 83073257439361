import AzureAuthenticationContext from './azure/AzureAuthenticationContext';
import {msalInstance} from './index'

const getIdToken = async (): Promise<string> => {
  const auth = localStorage.getItem('auth');
  if (!auth) {
    throw new Error();
  }
  const data = JSON.parse(auth);
  const token = await new AzureAuthenticationContext(msalInstance).acquireToken(data)
  return token.idToken;
};

export default getIdToken;
