import {AppBar, AppBarProps, Button, DashboardMenuItem, Layout, LayoutProps, MenuItemLink} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {routes} from './routes';
import ViewListIcon from '@material-ui/icons/ViewList';
import {useHistory} from 'react-router';

const fontFamily = ['Montserrat', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(',');

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontFamily,
  },
  selectLabel: {
    paddingRight: 10,
    color: 'white',
    fontSize: 'smaller',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontFamily,
  },
  selectedValue: {
    paddingLeft: 20,
    paddingRight: 20,
    color: 'white',
    fontSize: 'smaller',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontFamily,
  },
  spacer: {
    flex: 1,
  },
});

export const MenuPsa = () => {
  const menuItems = Object.values(routes)
    .filter((route) => route.hideFromMenu !== true)
    .map((route) => {
      const to = '/' + route.name;
      return <MenuItemLink key={route.name} to={to} primaryText={route.label} leftIcon={<ViewListIcon />} />;
    });
  return (
    <div style={{marginTop: 8, marginBottom: 16}}>
      <DashboardMenuItem />
      {menuItems}
    </div>
  );
};

const ChangelogButton = () => {
  const history = useHistory();
  const changelogRoute = routes.Changelog;
  const onClick = () => {
    history.push('/' + changelogRoute.name);
  };
  return (
    <Button label={changelogRoute.label} color="inherit" size="large" onClick={onClick}>
      <ViewListIcon />
    </Button>
  );
};

const AppBarPsa = (props: AppBarProps) => {
  const classes = useStyles();
  return (
    <AppBar {...props}>
      <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
      <span className={classes.spacer} />
      <ChangelogButton />
    </AppBar>
  );
};

export const LayoutPsa = (props: LayoutProps) => {
  return <Layout {...props} appBar={AppBarPsa} menu={MenuPsa} />;
};
