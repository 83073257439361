import {Datagrid, Filter, List, SearchInput, TextField, useListContext} from 'react-admin';
import {DataGridSpinner} from '../DataGridSpinner';
import {JsonSwitchTable} from '../JsonSwitchTable';
import {IBrandOutbound} from './BrandRegistryOutboundDataProvider';
import {useDataGridClassNames} from '../styles/datagridStyles';

const BrandRegistryOutboundTable = () => {
  const {data} = useListContext();
  const dataList = Object.values(data || {}) as IBrandOutbound[];
  const systemNames = new Set<string>();
  dataList.forEach((entry) => {
    Object.keys(entry)
      .filter((key) => ['id', 'code', 'canonicalName'].indexOf(key) === -1)
      .forEach((systemName) => {
        systemNames.add(systemName);
      });
  });
  const systemNamesColumns = Array.from(systemNames).map((systemName) => {
    return <TextField source={systemName} label={systemName} emptyText="-" />;
  });
  const datagridClass = useDataGridClassNames();
  return (
    <Datagrid isRowSelectable={() => false} classes={datagridClass}>
      <TextField source="code" label="Brand Code" />
      <TextField source="canonicalName" label="Canonical Brand Name" />
      {systemNamesColumns}
    </Datagrid>
  );
};

const BrandRegistryOutboundContent = (props: any) => {
  const FilterField = (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
    </Filter>
  );
  return (
    <List {...props} bulkActionButtons={false} exporter={false} empty={false} pagination={false} filters={FilterField}>
      <DataGridSpinner key="1">
        <BrandRegistryOutboundTable />
      </DataGridSpinner>
    </List>
  );
};

export const BrandRegistryOutboundList = (props: any) => {
  return <JsonSwitchTable {...props} table={BrandRegistryOutboundContent} />;
};
