import {SystemName} from '@adecco/tenant-brand-registry';
import {IGetListParams} from '../IGetListParams';
import {sort} from '../utils/sorting';

export class SystemNamesDataProvider {
  public getAllSystemNames(params: IGetListParams | undefined): SystemNameRow[] {
    const rows = this.getAllSystemNamesJson();
    return sort(params, rows);
  }

  public getAllSystemNamesJson(): SystemNameRow[] {
    return Object.values(SystemName).map((name: string, id: number) => {
      return {id, name};
    });
  }
}

export interface SystemNameRow {
  name: string;
}
