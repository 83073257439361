import {CountryRegistry} from '@adecco/tenant-brand-registry';
import {IGetListParams} from '../IGetListParams';
import {sort} from '../utils/sorting';

export class CountryRegistryDataProvider {

  public getAllCountries(params: IGetListParams | undefined): CountryRow[] {
    const rows = this.getAllCountriesJson();
    return sort(params, rows);
  }

  public getAllCountriesJson(): CountryRow[] {
    return Object.entries(CountryRegistry).map(([id, country]) => ({
      name: country.name,
      id,
      iso2: country.iso2,
      iso3: country.iso3 || '',
      callingCode: country.callingCode || '',
    }));
  }
}

export interface CountryRow {
  name: string;
  id: string;
  iso2: string;
  iso3: string;
  callingCode: string;
}
