import {ConsentClassificationRegistry, ConsentClassification, CONSENT_TYPE} from '@adecco/tenant-brand-registry';
import {IGetListParams} from '../IGetListParams';
import {sort} from '../utils/sorting';

export class ConsentClassificationDataProvider {

  public getAllConsentClassifications(params: IGetListParams | undefined): ConsentClassificationRow[] {
    const rows = this.getAllConsentClassificationsJson();
    return sort(params, rows);
  }

  public getAllConsentClassificationsJson(): ConsentClassificationRow[] {
    return ConsentClassificationRegistry.map((c: ConsentClassification, index: number) => {
      return Object.assign(c, {id: (index + 1).toString()})
    })
  }
}

export interface ConsentClassificationRow {
  id: string;
  consentType: CONSENT_TYPE;
}