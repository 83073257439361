import {AbstractDataProvider} from '../AbstractDataProvider';
import {TenantBrandRegistry, TenantPool, TenantPools} from '@adecco/tenant-brand-registry';
import {IGetListParams} from '../IGetListParams';
import {uniqueId} from 'lodash';
import {sort} from '../utils/sorting';

export class TenantPoolsDataProvider extends AbstractDataProvider {
  protected readonly resource = '';
  protected readonly tenantBrandRegistry = new TenantBrandRegistry();

  public async getOne(id: string): Promise<any> {
    return {};
  }

  protected generateId(item: any) {
    return item.id;
  }

  protected parseId(id: string): Record<string, any> {
    const [principalName, regionId] = id.split('-');
    return {principalName, regionId};
  }

  public async getAllPools(params: IGetListParams | undefined): Promise<ITenantPool[]> {
    const tenants = this.tenantBrandRegistry.getAllTenants();
    const pools: any[] = [...TenantPools].map(x => {
      const reduceFn = (result: {[key: string]: ICollapsedTenant[]}, collapsedTenant: number) => {
        const tenant = tenants.find(item => item.id === collapsedTenant);
        if (tenant?.country) {
          const current = result[tenant.country.name] || [];
          result[tenant.country.name] = [...current, {id: tenant.id, brand: tenant.brand?.canonicalName}];
        }
        return result;
      };
      return {
        id: uniqueId(),
        poolName: x.poolName,
        rootTenant: x.rootTenant,
        collapsed: x.collapsedTenants.reduce(reduceFn, {}),
      };
    });
    return sort(params, pools);
  }

  public async getTenantPoolsJson(): Promise<Readonly<TenantPool[]>> {
    return this.tenantBrandRegistry.getTenantPools();
  }
}

export interface ITenantPool {
  id: string;
  poolName: string;
  rootTenant: number;
  collapsed: ICollapsedTenant[];
}

export interface ICollapsedTenant {
  id: number;
  brand?: string;
}
