import {Datagrid, List, TextField} from 'react-admin';
import {DataGridSpinner} from '../DataGridSpinner';
import {useDataGridClassNames} from '../styles/datagridStyles';
import {CollapsedTenantItem} from './CollapsedTenantItem';
import {JsonSwitchTable} from '../JsonSwitchTable';
import {Box} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    paddingTop: 0,
  },
});

const TenantPoolsTable = (props: any) => {
  const datagridClass = useDataGridClassNames();
  const listClass = useStyles();
  return (
    <>
      <Box mt={2}>
        <Alert severity="info">Pool names are not the same as System names.</Alert>
      </Box>
      <List
        {...props}
        className={listClass.root}
        bulkActionButtons={false}
        exporter={false}
        empty={false}
        pagination={false}
      >
        <DataGridSpinner key="1">
          <Datagrid isRowSelectable={() => false} classes={datagridClass}>
            <TextField source="poolName" label="Pool Name" sortBy="poolName" />
            <TextField source="rootTenant" label="Root Tenant" sortBy="rootTenant" />
            <CollapsedTenantItem source="collapsed" label="Collapsed Tenants" sortBy="collapsed" />
          </Datagrid>
        </DataGridSpinner>
      </List>
    </>
  );
};

export const TenantPoolsList = (props: any) => {
  return <JsonSwitchTable {...props} table={TenantPoolsTable} />;
};
